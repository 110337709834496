var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "null-page"
  }, [_c('img', {
    staticClass: "img",
    attrs: {
      "src": require("../../assets/null.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.text))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };